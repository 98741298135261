@import 'themes';

@import '@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
};

html {
  font-size: 14px;
}
