@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400&display=swap');

//main colors
$color-butterfly: rgb(53, 119, 117);
//palette
$color-bargreen:#25493E;
$color-myrtlegreen: #457A74;
$color-opal: #96BAB6;
$color-pewterblu: #7199A5;
$color-powderblu: #9FD9DA;
$color-background: #EDEDED;
$color-timberflow: #D4D2CB;
$color-card-scrollbar: #d4d9e2;

$card-scrollbar-width: 0.4125rem;

$font-family-main: Montserrat;

//Generalised colors
$color-licorice:#292929;
$color-lightsalmon: #fda173;
$color-purplenavy: #435582;
$color-whitesmoke: #f0f2f5;
$color-menu-not-selected: #a7a7a7;
$font-weight-light: 300;
